<template>
  <a-layout-header class="layout-header" :style="{ height: device.mobile ? '50px' : '74px' }">
    <a-row>
      <a-col :span="24">
        <div class="letwecare-breadcrumb">
          <a-breadcrumb
            v-if="!device.mobile"
            class="__breadcrumb"
            :routes="breadcrumbRoutes"
          >
            <template #itemRender="{ routes, route }">
              <span v-if="routes.indexOf(route) === routes.length - 1">
                {{ route.breadcrumbName }}
              </span>
              <router-link v-else :to="route.path">
                {{ route.breadcrumbName }}
              </router-link>
            </template>
          </a-breadcrumb>

          <a-page-header
            class="__page-header"
            style="height: 63px; padding-left: 0;"
            title="知識學堂"
            @back="backToForumPage"
          >
            <!-- <template #itemRender="{ route, params, routes, paths }">
              <span v-if="routes.indexOf(route) === routes.length - 1">{{route.breadcrumbName}}</span>
              <router-link :to="paths.join('/')">{{route.breadcrumbName}}</router-link>
            </template> -->
          </a-page-header>
        </div>
      </a-col>
    </a-row>
  </a-layout-header>

  <a-layout-content class="layout-content" :style="{ padding: device.mobile ? '0' : '24px' }">
    <div class="app-forum-article-page">
      <div class="__wrapper" v-if="Object.keys(articleData).length">
        <a-row :gutter="device.mobile ? 0 : 16">
          <a-col
            v-if="!device.mobile"
            flex="50px" align="center"
          >
            <a-card
              class="forum-article-actions"
              :bordered="false"
            >
              <div>
                <span
                  @click="setArticleReaction('up')"
                  class="action-btn mdi mdi-arrow-up"
                  :class="{'reaction--up': articleData.my_reaction && articleData.my_reaction.reaction == 'up'}"
                />
              </div>
              <div
                class="reaction-score my-1"
                :class="[
                  { 'reaction--up': articleData.my_reaction && articleData.my_reaction.reaction == 'up'},
                  { 'reaction--down': articleData.my_reaction && articleData.my_reaction.reaction == 'down'}
                ]"
              >
                {{ articleData.reaction_score }}
              </div>
              <div>
                <span
                  @click="setArticleReaction('down')"
                  class="action-btn mdi mdi-arrow-down"
                  :class="{'reaction--down': articleData.my_reaction && articleData.my_reaction.reaction == 'down'}"
                />
              </div>
              
              <template v-if="articleData.author.uid == authStore.info.uid">
                <a-divider class="my-2" />

                <a-tooltip placement="left">
                  <template #title>
                    <span>編輯</span>
                  </template>
                  <a-button type="link" shape="circle" @click="toEditorPage(articleData.id)">
                    <template #icon>
                      <span class="mdi mdi-pencil" :style="{ color: '#212121', fontSize: '1rem' }"></span>
                    </template>
                  </a-button>
                </a-tooltip>
              </template>
            </a-card>
          </a-col>

          <a-col flex="1">
            <!-- Artcile -->
            <a-card
              class="forum-article"
              :bordered="false"
            >
              <div class="article-title">{{ articleData.title }}</div>
              <div class="article-author">
                <a-avatar
                  :src="articleData.author.avatar!=null ? articleData.author.avatar.uri : require('@/assets/icons/avatar.png')"
                  :size="24"
                  :style="{ marginBottom: '5px', marginRight: '12px' }"
                />
                <!-- <a-typography-link>{{ articleData.author.name }}</a-typography-link> -->
                <a-typography-text>{{ articleData.author.name }}</a-typography-text>
                <a-typography-text class="mx-2" type="secondary" :style="{ fontSize: '1.2rem' }">·</a-typography-text>
                <a-typography-text type="secondary">{{ DateUtil.formatDatetime(articleData.created_datetime) }}</a-typography-text>
              </div>
              <div
                class="article-content"
                v-html="articleData.content"
              />
            </a-card>
            <!-- Artcile -->

            <!-- Artcile Responses -->
            <!-- <a-card
              class="forum-article-responses mt-3"
              :bordered="false"
            >
              123
            </a-card> -->
            <!-- Artcile Responses -->
          </a-col>
        </a-row>
      </div>
    </div>
  </a-layout-content>
</template>

<script>
import { defineComponent, ref, onMounted } from 'vue'
import { useRoute } from 'vue-router'
import { createDeviceDetector } from 'next-vue-device-detector'
import { useAuthStore } from '@/store/auth'
import { useAppStore } from '@/store/app'
import api from '@/apis'
import DateUtil from '@/utils/DateUtil'

export default defineComponent({
  components: {},
  setup() {
    const route = useRoute();
    const device = createDeviceDetector();

    /* Store */
    const authStore = useAuthStore();
    const appStore = useAppStore();

    /* Routes breadcrumb */
    const breadcrumbRoutes = ref([
      {
        path: '/app/forum',
        breadcrumbName: '知識學堂'
      }
    ]);

    /* Data */
    const articleData = ref({});

    /* Api */
    const getArticleInfo = (async () => {
      const response = await api.v1.forum.artcileInfo(route.params.article_id);
      articleData.value = response.data.result;

      // set reaction
      articleData.value.my_reaction = null;
      articleData.value.reaction_score = 0;
      articleData.value.reactions.forEach(reaction => {
        if (reaction.reaction == 'up') articleData.value.reaction_score += 1;
        else if (reaction.reaction == 'down') articleData.value.reaction_score -= 1;

        if (reaction.user_id == authStore.info.uid) {
          articleData.value.my_reaction = reaction;
        }
      });
    });

    /* Mounted */
    onMounted(async () => {
      await getArticleInfo();

      // set routes breadcrumb
      breadcrumbRoutes.value.push({
        path: `/app/forum/article/${ route.params.article_id }`,
        breadcrumbName: articleData.value.title
      });
    });

    return {
      device,

      /* Store */
      authStore,
      appStore,

      /* Routes breadcrumb */
      breadcrumbRoutes,

      /* Data */
      articleData,

      /* Api */
      api,

      /* Utils */
      DateUtil
    }
  },
  methods: {
    /* Router */
    backToForumPage() {
      this.$router.push({ name: 'AppForum' });
    },
    toEditorPage(articleId) {
      this.$router.push({
        name: 'AppForumEditor',
        query: {
          id: articleId
        }
      });
    },

    /* Functions */
    async setArticleReaction(reaction) {
      // 如果沒有紀錄, 新增 reaction
      if (this.articleData.my_reaction == null) {
        const response = await this.api.v1.forum.insertOneArticleReaction(this.articleData.id, {
          reaction: reaction
        });

        const reactionItem = {
          id: response.data.result.id,
          reaction: reaction
        }
        this.articleData.my_reaction = reactionItem;
        this.articleData.reaction_score += 1;
        return
      }

      console.log(this.articleData)

      // 更新 reaction
      if (this.articleData.my_reaction.reaction == reaction) return
      else {
        await this.api.v1.forum.updateOneArticleReaction(this.articleData.id, {
          id: this.articleData.my_reaction.id,
          reaction: reaction
        });

        // set reaction
        this.articleData.my_reaction.reaction = reaction;
        if (reaction == 'up') this.articleData.reaction_score += 2;
        else this.articleData.reaction_score -= 2;
      }
    }
  }
})
</script>

<style lang="scss">
.app-forum-article-page {
  .__wrapper {
    width: 100%;
    max-width: 800px;
    margin: 0 auto;
  }

  .forum-article-actions {
    width: 52px;
    position: sticky;
    top: 0px;

    .reaction-score {
      color: #212121;
      font-size: 1.3rem;
    }

    .action-btn {
      color: #757575;
      font-size: 1.5rem;
      cursor: pointer;
    }

    .reaction--up {
      color: #1976D2;
    }

    .reaction--down {
      color: #F44336;
    }

    .ant-card-body {
      padding: 8px 0;
    }
  }

  .forum-article {
    padding: 0 0px;

    /* md */
    @media screen and (min-width: 970px) {
      padding: 0 12px;
    }

    .article-title {
      color: #212121;
      font-size: 1.7rem;
      font-weight: 500;
      letter-spacing: 0.01rem;
      margin-bottom: 8px;
    }

    .article-author {
      margin-bottom: 30px;
    }

    .article-content {
      font-size: 1rem;

      p {
        margin: 4px 0 12px 0;
        line-height: 1.8rem;
      }

      &:focus-visible {
        outline: none;
      }

      > * + * {
        margin-top: 0.75em;
      }

      ul,
      ol {
        padding: 0 1rem;
      }

      h1,
      h2,
      h3,
      h4,
      h5,
      h6 {
        line-height: 1.1;
      }

      code {
        background-color: rgba(#616161, 0.1);
        color: #616161;
      }

      pre {
        background: #0D0D0D;
        color: #FFF;
        font-family: 'JetBrainsMono', monospace;
        padding: 0.75rem 1rem;
        border-radius: 0.5rem;

        code {
          color: inherit;
          padding: 0;
          background: none;
          font-size: 0.8rem;
        }
      }

      img {
        max-width: 100%;
        height: auto;
      }

      blockquote {
        padding-left: 1rem;
        border-left: 2px solid rgba(#0D0D0D, 0.1);
      }

      hr {
        border: none;
        border-top: 1px solid rgba(#E0E0E0, 0.1);
        margin: 2rem 0;
      }

      /* Youtube video */
      iframe {
        border: 8px solid #000;
        border-radius: 4px;
        min-width: 200px;
        min-height: 200px;
        display: block;
        outline: 0px solid transparent;
        margin-bottom: 12px;
      }

      div[data-youtube-video] {
        cursor: move;
        padding-right: 24px;
      }
    }
  }
}
</style>